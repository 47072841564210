export const projects = [
  {
    title: "Project 1",
    desc: "Descriptino of the project",
    tags: [
      {
        name: "NextTs",
        color: "red-600",
      },
      {
        name: "TailwindCSS",
        color: "green-600",
      },
    ],
    img: "https://cdn.discordapp.com/attachments/1096426516667174994/1109025615442608168/pvz.png",
    link: "https://pvz-2-api.vercel.app/",
    code: "https://github.com/code-cracked/plants-vs-zombies-api",
  }
];
